












































import { Vue, Component } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { mixins } from "vue-class-component";
import ButtonMixin from "@/mixins/ButtonMixin";
import ReplyButton from "@/components/Button/ReplyButton.vue";
import ReplyAllButton from "@/components/Button/ReplyAllButton.vue";
import ForwardButton from "@/components/Button/ForwardButton.vue";
import { namespace } from "vuex-class";
import moment from "moment";

const MailboxModule = namespace("mailbox");

@Component({
  components: { FscSimpleCard, ReplyButton, ReplyAllButton, ForwardButton },
})
export default class CurrentEmailDetailsForm extends mixins(ButtonMixin) {
  public name = "CurrentEmailDetailsForm";
  /* Getters start*/
  @MailboxModule.Getter("getSelectedEmailContent")
  private email: any;
  /* Getters end */

  /* Actions start */
  @MailboxModule.Action("downloadFileAction")
  private downloadFileAction: any;

  /* Actions end */

  public get time(): any {
    return (t: any) => moment({ hour: t.hour, minute: t.minute });
  }

  /*methods*/
  public onReplyClick(): void {
    this.$root.$emit("reply", {
      to: this.email.from,
      id: this.email.id,
      cc: "",
      repliedEmailId: this.email.id,
      repliedAllEmailId: 0,
      forwardedEmailId: 0,
      subject: `Re: ${this.email.subject}`,
    });
  }

  public onReplyAllClick(): void {
    this.$root.$emit("reply-all", {
      to: this.email.from,
      id: this.email.id,
      cc: "",
      repliedEmailId: 0,
      repliedAllEmailId: this.email.id,
      forwardedEmailId: 0,
      subject: `Re: ${this.email.subject}`,
    });
  }

  public onForwardClick(): void {
    this.$root.$emit("forward", {
      to: "",
      id: this.email.id,
      cc: "",
      repliedEmailId: 0,
      repliedAllEmailId: 0,
      forwardedEmailId: this.email.id,
      subject: `Fw: ${this.email.subject}`,
    });
  }

  public downloadFile(attachment: any) {
    this.downloadFileAction(attachment);
  }
}
